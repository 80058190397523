import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../Hooks';
import { storeTokenInLocalStorage } from '../../Common';
import { IsLoggedInContext } from '../../BalContext';
import { toast } from 'react-hot-toast';

const SignIn = () => {
  const navigate = useNavigate();
  const { user, authenticated } = useUser(true);
  const loggedInContext = React.useContext(IsLoggedInContext);
  if (user || authenticated) {
    loggedInContext.setLoggedIn(true);
    navigate('/Admin')
  }

  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');

  const signIn = async () => {
    const data = {
      username: username,
      password: password
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/ValidateUser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then((response) => {
        if (response.status === 401) {
          toast.error("Fel användarnamn eller lösenord");
        }
        else if (!response.ok) {
          toast.error("Något gick fel med inloggningen");
        }
        return response.text();
      });

      if (!response) {
        console.log('Something went wrong during signing in: ', response);
        loggedInContext.setLoggedIn(false);
        return;
      }
      storeTokenInLocalStorage(response);
      loggedInContext.setLoggedIn(true);
      navigate('/Admin')
    }
    catch (err) {
      console.log('Some error occured during signing in: ', err);
      loggedInContext.setLoggedIn(false);
    }
    finally {
    }
  };


  return (
    <div>
      <input
        type="text"
        placeholder="Användarnamn"
        value={username}
        onChange={(e) => { setUsername(e.target.value); }}
      />
      <input
        type="password"
        placeholder="*******" value={password}
        onChange={(e) => { setPassword(e.target.value); }}
      />
      <button onClick={signIn}>
        Logga in
      </button>
    </div>
  );
}

export default SignIn;