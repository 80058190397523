import * as React from 'react';
import './index.css';
import { CircularProgress } from '@mui/material';
import { getTokenFromLocalStorage } from '../../../Common';
import { BalContext } from '../../../BalContext';
import { useUser } from '../../../Hooks';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';

export default function UpdateAttendees() {
  const [attendees, setAttendees] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [sortFunc, setSortFunc] = React.useState(() => () => {});
  const [sortProp, setSortProp] = React.useState();
  const [desc, setDesc] = React.useState();
  const balSettings = React.useContext(BalContext);
  const { user, authenticated } = useUser(true);

  const getAttendees = () => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/GetAttendees`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`
      }
    })
    .then((response) => {
      if (response.status === 401) {
        toast.error("Du har inte rättigheter att göra detta");
      }
      else if (!response.ok) {
        toast.error("Något gick fel när anmälda skulle hämtas");
      }
      return response.json()
    })
    .then((data) => {
      setAttendees(data);
    }).finally(setIsLoading(false));
  }

  React.useEffect(() => {
    getAttendees();
  }, []);

  const resolveKeyPath = (obj, path) => {
    path = path.split(".");
    var current = obj;
    while (path.length) {
      if (typeof current !== "object") return undefined;
      current = current[path.shift()];
    }
    return current;
  };

  const sortTableOn = (propertyToSort) => {
    setSortProp(propertyToSort);
    var mod = desc ? -1 : 1;
    const sortFuncOnProp = (a, b) => {
      var lca = resolveKeyPath(a, propertyToSort).toString().toLowerCase();
      var lcb = resolveKeyPath(b, propertyToSort).toString().toLowerCase();
      return lca > lcb ? 1 * mod : lca < lcb ? -1 * mod : 0;
    };
    setSortFunc(() => sortFuncOnProp);
  };

  if (!user || !authenticated) {
    return null;
  }

  if (isLoading) {
    return (
      <CircularProgress />
    );
  };

  if (!isLoading && (!attendees || attendees.length === 0)) {
    return (
      <p>Inga anmälda än! Anmälan öppnar { balSettings.startDate }</p>
    );
  };

  const getState = (stateNum) => {
    switch (stateNum) {
      case 0:
        return "Kommer";
      case 1:
        return "Reserv";
      case 2:
        return "Kommer ej";
      default:
        return "";
    }
  }

  return (
    <div className='updateAttendeesPage'>
      <table>
        <thead>
          <tr>
            <th
              onClick={() => {
                sortTableOn("responses.name");
                setDesc(!desc);
              }}
            >
              Namn
              {sortProp === "responses.name" &&
                (desc === undefined ? null : desc ? "↓" : "↑")}
            </th>
            <th
              onClick={() => {
                sortTableOn("responses.title");
                setDesc(!desc);
              }}
            >
              Titel
              {sortProp === "responses.title" &&
                (desc === undefined ? null : desc ? "↓" : "↑")}
            </th>
            <th
              onClick={() => {
                sortTableOn("responses.email");
                setDesc(!desc);
              }}
            >
              Email
              {sortProp === "responses.email" &&
                (desc === undefined ? null : desc ? "↓" : "↑")}
            </th>
            <th
              onClick={() => {
                sortTableOn("state");
                setDesc(!desc);
              }}
            >
              Status
              {sortProp === "state" &&
                (desc === undefined ? null : desc ? "↓" : "↑")}
            </th>
            <th
              onClick={() => {
                sortTableOn("payed");
                setDesc(!desc);
              }}
            >
              Markerad som betald och bekräftad
              {sortProp === "payed" &&
                (desc === undefined ? null : desc ? "↓" : "↑")}
            </th>
            <th>
              Uppdatera
            </th>
          </tr>
        </thead>
        <tbody>
          { attendees.sort(sortFunc).map((x, i) => (
            <tr key={ i }>
              <td>{ x.responses.name }</td>
              <td>{ x.responses.title }</td>
              <td>{ x.responses.email }</td>
              <td>{ getState(x.state) }</td>
              <td>{ x.payed ? "Ja" : "Nej" }</td>
              <td><Link to={x.id}>Uppdatera</Link></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}