import * as React from 'react';
import { getAuthenticatedUser } from '../Common';
import { useNavigate } from 'react-router-dom';

export function useUser(redirectIfNotSignedIn) {
  const [user, setUser] = React.useState(null);
  const [authenticated, setAuthenticated] = React.useState(false);
  const navigate = useNavigate();
  /* eslint-disable */
  React.useEffect(() => {
    async function getUserDetails() {
      const { authenticated, user } = await getAuthenticatedUser();
      if (!authenticated && redirectIfNotSignedIn) {
        navigate('/signin');
        return;
      }
      setUser(user);
      setAuthenticated(authenticated);
    }
    getUserDetails();
  }, []);
  /* eslint-enable */
  return { user, authenticated };
}