import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: '#229B17'
    }
  },
  typography: {
    fontFamily: [
      'Garamond'
    ].join(','),
  },
});

export default theme;