import * as React from 'react';
import './index.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useContext } from 'react';
import { BalContext, IsLoggedInContext } from '../../BalContext';
import { Link } from "react-router-dom";

export default function NavBar({value, navigation, handleChange}) {
  const balSettings = useContext(BalContext);
  const loggedInContext = useContext(IsLoggedInContext);
  React.useEffect(() => {
    let nameFromNavigation = null;
    if (navigation) {
      nameFromNavigation = Object.keys(navigation).find(x => navigation[x] === value);
    }
    const tabName = nameFromNavigation ? nameFromNavigation : value;
    document.title = balSettings ? `${balSettings.balName} | ${tabName}` : 'Bal Blekingska';
  }, [value, balSettings, navigation]);

  return (
    <div className='navigationTabs'>
      <Tabs value={ value } onChange={ handleChange } variant='scrollable'>
        { navigation &&
          Object.keys(navigation).map((keyName, i) => (
            <Tab label={ keyName } key={ i } value={ navigation[keyName] } component={Link} to={`/${keyName}`} />
          )) 
        }
        <Tab label='Anmälda' value='Anmalda' component={Link} to='Anmalda' />
        <Tab label='Anmälan' value='Anmalan' component={Link} to='Anmalan' />
        { loggedInContext.loggedIn && <Tab label='Admin' value='Admin' component={Link} to='Admin' /> }
      </Tabs>
    </div>
  );
}