import * as React from 'react';
import './index.css';
import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { BalContext } from '../../../../BalContext';
import { useUser } from '../../../../Hooks';
import { getTokenFromLocalStorage } from '../../../../Common';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';

export default function UpdateAttendeeDetails() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [attendee, setAttendee] = React.useState();
  const balSettings = React.useContext(BalContext);
  const { user, authenticated } = useUser(true);
  const [ watchedPreviousVisits, setWatchedPreviousVisits ] = React.useState(0);
  const { register, handleSubmit, reset, watch } = useForm();
  let { id } = useParams();

  React.useEffect(() => {
    const subscription = watch((value) => {
      setWatchedPreviousVisits(value.previousVisits);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);

   /* eslint-disable */
  React.useEffect(() => {
    getAttendees();
  }, []);
   /* eslint-enable */

  const getState = (stateNum) => {
    switch (stateNum) {
      case 0:
        return "coming";
      case 1:
        return "reserve";
      case 2:
        return "notComing";
      default:
        return "";
    }
  }

  const getUpdatedState = (stateString) => {
    switch (stateString) {
      case "coming":
        return 0;
      case "reserve":
        return 1;
      case "notComing":
        return 2;
      default:
        return 0;
    }
  }

  const getAttendees = () => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/GetAttendees`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`
      }
    })
    .then((response) => {
      if (response.status === 401) {
        toast.error("Du har inte rättigheter att göra detta");
      }
      else if (!response.ok) {
        toast.error("Något gick fel när anmälda skulle hämtas");
      }
      return response.json();
    })
    .then((data) => {
      const loadedUser = data.find(x => x.id === id);
      setAttendee(loadedUser);
      reset({ ...loadedUser.responses, userState: getState(loadedUser.state) });
    }).finally(setIsLoading(false));
  }

  if (!user || !authenticated) {
    return null;
  }

  if (isLoading) {
    return (
      <CircularProgress />
    );
  };

  const onSubmit = (data) => {
    postResponse(data);
  }

  const postResponse = async (data) => {
    const {userState: _, ...responesToUpdate} = data;
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/api/UpdateFormResponse`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getTokenFromLocalStorage()}`
        },
        body: JSON.stringify([{
          id: attendee.id,
          state: getUpdatedState(data.userState),
          payed: attendee.payed,
          responses: responesToUpdate
        }]),
      }).then((response) => {
        if (response.status === 401) {
          toast.error("Du har inte rättigheter att göra detta");
        }
        else if (!response.ok) {
          toast.error("Något gick fel när anmälan uppdaterades");
        }
        else {
          toast.success("Anmälan uppdaterad");
        }
      });
    } catch (error) {
      console.error("Error:", error);
    }
  }

  return (
    <>
      <form onSubmit={ handleSubmit(onSubmit) }>
        <div className='primary'>
          <label htmlFor='name'>Namn</label>
          <br/>
          <input
            id='name'
            { ...register('name', { required: false }) }
            type='text'
          />
          <br/>
          <label htmlFor='idNumber'>Personnummer</label>
          <br/>
          <input
            id='idNumber'
            { ...register('idNumber', { required: false }) }
            type='text'
          />
          <br/>
          <label htmlFor='address'>Adress</label>
          <br/>
          <textarea
            id='address'
            { ...register('address', { required: false }) }
            type='text'
          />
          <br/>
          <label htmlFor='email'>Email</label>
          <br/>
          <input
            id='email'
            { ...register('email', { required: false }) }
            type='email'
          />
          <br/>
          <label htmlFor='phone'>Telefonnummer</label>
          <br/>
          <input
            id='phone'
            { ...register('phone', { required: false }) }
            type='text'
          />
          <br/>
          <label htmlFor='title'>Titel</label>
          <br/>
          <input
            id='title'
            { ...register('title', { required: false }) }
            type='text'
          />
          <br/>
          <label htmlFor='company'>Följe</label>
          <br/>
          <input
            id='company'
            { ...register('company', { required: false }) }
            type='text'
          />
        </div>
        <div className='secondary'>
          <p>
            { balSettings?.student &&
              <>
                <input
                  id='student'
                  { ...register('ticketType', { required: false }) }
                  type='radio'
                  name={ 'ticketType' }
                  value='student'
                />
                <label htmlFor='student'>Student: ({ balSettings?.student } kr)</label>
                <br/>
              </>
            }
            { balSettings?.nonStudent &&
              <>
                <input
                  id='nonStudent'
                  { ...register('ticketType', { required: false }) }
                  type='radio'
                  name={ 'ticketType' }
                  value='nonStudent'
                />
                <label htmlFor='nonStudent'>Ickestudent: ({ balSettings?.nonStudent } kr)</label>
                <br/>
              </>
            }
            { balSettings?.active &&
              <>
                <input
                  id='active'
                  { ...register('ticketType', { required: false }) }
                  type='radio'
                  name={ 'ticketType' }
                  value='active'
                />
                <label htmlFor='active'>Förtroendevald: ({ balSettings?.active } kr)</label>
                <br/>
              </>
            }
            { balSettings?.giver &&
              <>
                <input
                  id='giver'
                  { ...register('ticketType', { required: false }) }
                  type='radio'
                  name={ 'ticketType' }
                  value='giver'
                />
                <label htmlFor='giver'>Gynnare: ({ balSettings?.giver } kr)</label>
                <br/>
              </>
            }
          </p>
          <p>
            <input
              id='alcohol'
              { ...register('alcoholChoice', { required: false }) }
              type='radio'
              name='alcoholChoice'
              value='alcohol'
            />
            <label htmlFor='alcohol'>Alkohol till maten: (0 kr)</label>
            <br/>
            <input
              id='alcoholFree'
              { ...register('alcoholChoice', { required: false }) }
              type='radio'
              name='alcoholChoice'
              value='alcoholFree'
            />
            <label htmlFor='alcoholFree'>Alkoholfritt till maten: ({ balSettings?.alcoholFree ? (`-${balSettings?.alcoholFree}`) : '0' } kr)</label>
          </p>
          <p>
            { balSettings?.book &&
              <>
                <input
                  id='book'
                  { ...register('book', { required: false }) }
                  type='checkbox'
                />
                <label htmlFor='book'>Sångbok: ({ balSettings?.book } kr)</label>
                <br/>
              </>
            }
            { balSettings?.sexa &&
              <>
                <input
                  id='sexa'
                  { ...register('sexa', { required: false }) }
                  type='checkbox'
                />
                <label htmlFor='sexa'>Sexa: ({ balSettings?.sexa } kr)</label>
                <br/>
              </>
            }
            { balSettings?.medal &&
              <>
                <input
                  id='medal'
                  { ...register('medal', { required: false }) }
                  type='checkbox'
                />
                <label htmlFor='medal'>{((watchedPreviousVisits === "4" || watchedPreviousVisits === "9" || watchedPreviousVisits === 4 || watchedPreviousVisits === 9) ? `Inbyte av medalj: (${balSettings.medalTrade} kr)` : `Medalj: (${balSettings.medal} kr)`)}</label>
                <br/>
              </>
            }
            { balSettings?.lunch &&
              <>
                <input
                  id='lunch'
                  { ...register('lunch', { required: false }) }
                  type='checkbox'
                />
                <label htmlFor='lunch'>Ballumch: ({ balSettings?.lunch } kr)</label>
                <br/>
              </>
            }
          </p>
          { balSettings?.snaps &&
            <>
              <label htmlFor='snaps'>Snapsbiljetter: ({ balSettings?.snaps } kr)</label>
              <br/>
              <input
                id='snaps'
                { ...register('snaps', { required: false }) }
                type='number'
                min="0"
                step="1"
              />
              <br/>
            </>
          }
          <label htmlFor='previousVisits'>Tidigare antal Höstblot</label>
          <br/>
          <input
            id='previousVisits'
            { ...register('previousVisits', { required: false }) }
            type='number'
            min="0"
            step="1"
          />
          <br/>
          <p>
            <input
              id='vegetarian'
              { ...register('vegetarian', { required: false }) }
              type='checkbox'
            />
            <label htmlFor='vegetarian'>Vegetarian</label>
            <br/>
            <input
              id='vegan'
              { ...register('vegan', { required: false }) }
              type='checkbox'
            />
            <label htmlFor='vegan'>Vegan</label>
            <br/>
            <input
              id='lactoseFree'
              { ...register('lactoseFree', { required: false }) }
              type='checkbox'
            />
            <label htmlFor='lactoseFree'>Laktosfritt</label>
            <br/>
            <input
              id='glutenFree'
              { ...register('glutenFree', { required: false }) }
              type='checkbox'
            />
            <label htmlFor='glutenFree'>Glutenfritt</label>
            <br/>
            <br/>
            <label htmlFor='otherFoodPrefs'>Övriga matpreferenser</label>
            <br/>
            <textarea
              id='otherFoodPrefs'
              { ...register('otherFoodPrefs', { required: false }) }
              type='text'
            />
          </p>
          <br/>
          <p>
            <input
              id='coming'
              { ...register('userState', { required: false }) }
              type='radio'
              name={ 'userState' }
              value='coming'
            />
            <label htmlFor='coming'>Kommer på balen</label>
            <br/>
            <input
              id='reserve'
              { ...register('userState', { required: false }) }
              type='radio'
              name={ 'userState' }
              value='reserve'
            />
            <label htmlFor='reserve'>Reservlista</label>
            <br/>
            <input
              id='notComing'
              { ...register('userState', { required: false }) }
              type='radio'
              name={ 'userState' }
              value='notComing'
            />
            <label htmlFor='notComing'>Kommer ej på balen</label>
            <br/>
          </p>
          <input type='submit' value='Uppdatera deltagare' />
        </div>
      </form>
    </>
  );
}