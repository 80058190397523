
// import bite from '../../Images/bite.png'
import eka from '../../Images/eka.png'
// import eriks from '../../Images/Eriks.jpg'
import lexis from '../../Images/lexis.png'
// import lundachark from '../../Images/lundachark.png'
// import rajraj from '../../Images/rajraj.png'
import shady from '../../Images/shady.png'
// import wolkes from '../../Images/wolkes.png'
import './index.css';

export default function Footer() {

  return (
    <div className='Sponsors'>
      {/* <a href="https://bitekitchens.com/"><img src={ bite } width='100px' alt='Bite' /></a> */}
      <a href="https://www.facebook.com/ekablommor/"><img src={ eka } width='100px' alt='Eka blommor' /></a>
      {/* <a href="https://www.eriks-biluthyrning.se/"><img src={ eriks } width='100px' alt='Eriks biluthyrning' /></a> */}
      <a href="https://www.lexis.se/"><img src={ lexis } width='100px' alt='Lexis papper' /></a>
      {/* <a href="https://lundachark.se/"><img src={ lundachark } width='100px' alt='Lundachark' /></a> */}
      {/* <a href="https://www.facebook.com/rajrajcraftbeer/"><img src={ rajraj } width='100px' alt='RajRaj Craft Beer' /></a> */}
      <a href="https://shadyburgers.se/shady-burgers-lund/"><img src={ shady } width='100px' alt='Shady Burgers' /></a>
      {/* <a href="https://wolkespunsch.se/"><img src={ wolkes } width='100px' alt='Wolkes Punsch' /></a> */}
    </div>
  );
}