import * as React from 'react';
import './index.css';
import { CircularProgress } from '@mui/material';
import { BalContext } from '../../BalContext';
import { toast } from 'react-hot-toast';

export default function Attendees() {
  const [attendees, setAttendees] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const balSettings = React.useContext(BalContext);

  React.useEffect(() => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/GetAttendingAttendeesSafe`)
    .then((response) => {
      if (!response.ok) {
        toast.error("Något gick fel när anmälda skulle hämtas");
      }
      return response.json();
    })
    .then((data) => setAttendees(data));
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return (
      <CircularProgress />
    );
  };

  if (!isLoading && (!attendees || attendees.length === 0)) {
    return (
      <p>Inga anmälda än! Anmälan öppnar { balSettings.startDate }</p>
    );
  };

  return (
    <div className='attendeesPage'>
      <table>
        <thead>
          <tr>
            <th>
              Namn
            </th>
            <th>
              Titel
            </th>
          </tr>
        </thead>
        <tbody>
          { attendees.sort((a,b) => {
              a = a.responses.name.toLowerCase();
              b = b.responses.name.toLowerCase();
              if( a === b) return 0;
              return a < b ? -1 : 1;
            }).map((x, i) => (
            <tr key={ i }>
              <td>{ x.responses.name }</td>
              <td>{ x.responses.title }</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}