import { Checkbox } from '@mui/material';
import * as React from 'react';
import { getTokenFromLocalStorage } from '../../../Common';
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast';
import './index.css';

export default function ManageAttendees() {
  const [attendees, setAttendees] = React.useState([]);
  const [checkedList, setChecked] = React.useState([]);
  const navigate = useNavigate();

  const getAttendees = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/GetAttendees`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenFromLocalStorage()}`
      }
    })
    .then((response) => {
      if (response.status === 401) {
        toast.error("Du har inte rättigheter att göra detta");
      }
      else if (!response.ok) {
        toast.error("Något gick fel när anmälda skulle hämtas");
      }
      return response.json();
    })
    .then((data) => {
      setAttendees(data);
      setChecked(data.filter(x => x.state === 0).map(x => ({
        id: x.id,
        isChecked: false
      })));
    });
  }

  React.useEffect(() => {
    getAttendees();
  }, []);

  const handleChange = (id, check) => {
    let clonedChecked = structuredClone(checkedList);
    const modifiedCheckedList = clonedChecked.map(obj => {
      if (obj.id === id) {
        return { ...obj, isChecked: check };
      }
      return obj;
    });
    setChecked(modifiedCheckedList);
  }

  const getIsChecked = (id) => {
    return checkedList.find(x => x.id === id)?.isChecked ?? false;
  }

  const updatePaid = async () => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/api/UpdateFormResponse`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getTokenFromLocalStorage()}`
        },
        body: JSON.stringify(attendees.filter(x => checkedList.some(c => c.id === x.id && c.isChecked)).map(x => ({
          id: x.id,
          responses: x.responses,
          payed: true,
          state: x.state
        }))),
      }).then((response) => {
        if (response.status === 401) {
          toast.error("Du har inte rättigheter att göra detta");
        }
        else if (!response.ok) {
          toast.error("Något fick fel när anmälan skulle uppdateras");
        }
        else {
          toast.success("Anmälda markerade som betalda");
        }
      });
      getAttendees();
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const downloadAttendees = () => {
		fetch(`${process.env.REACT_APP_API_URL}/api/GetExcel`, {
      method: "GET",
      headers: {
        "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        Authorization: `Bearer ${getTokenFromLocalStorage()}`
      }}).then((response) => {
        if (response.status === 401) {
          toast.error("Du har inte rättigheter att göra detta");
        }
        else if (!response.ok) {
          toast.error("Något gick fel när anmälda skulle exporteras");
        }
        response.blob().then(blob => {
				let url = window.URL.createObjectURL(blob);
				let a = document.createElement('a');
				a.href = url;
				a.download = `deltagare_${new Date().toISOString()}.xlsx`;
				a.click();
      });
		});
  };

  return (
    <>
      <div>        
        <p>Totalt antal anmälda: {attendees.filter(x => x.state === 0).length}</p>
        <p>Varav {attendees.filter(x => x.payed && x.state === 0).length} har betalat</p>
        <p>Totalt antal på reservlista: {attendees.filter(x => x.state === 1).length}</p>
        <button onClick={downloadAttendees}>Exportera anmälda</button>
        <button onClick={() => navigate('/UpdateAttendees')}>Uppdatera anmälda</button>
      </div>
      {
        attendees.filter(x => !x.payed && x.state === 0).length > 0
        ?
        (
          <div className='manageAttendeesPage'>
            <table>
              <thead>
                <tr>
                  <th>Refnbr</th>
                  <th>Namn</th>
                  <th>Email</th>
                  <th>Pris</th>
                  <th>Har betalat</th>
                </tr>
              </thead>
              <tbody>
                { attendees.filter(x => !x.payed && x.state === 0).map(attendee =>  {
                  return (
                    <tr key={attendee.id}>
                      <td>{attendee.orderNumber}</td>
                      <td>{attendee.responses.name}</td>
                      <td>{attendee.responses.email}</td>
                      <td>{attendee.totalPrice}</td>
                      <td><Checkbox checked={getIsChecked(attendee.id)} onChange={(e, check) => handleChange(attendee.id, check)}/></td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <button onClick={updatePaid}>Uppdatera betalda</button>
          </div>
        )
        :
        (
          <div>
            <p>
              Inga nya registreringar har tillkommit.
            </p>
          </div>
        )
      }
    </>
  );
}