import * as React from 'react';
import './index.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PageEditor from '../PageEditor';
import { getTokenFromLocalStorage } from '../../../Common';
import { toast } from 'react-hot-toast';

export default function PageSettings(props) {
  const [id, setId] = React.useState('Ny sida');
  const [content, setContent] = React.useState('');
  const [idToUpdate, setIdToUpdate] = React.useState(null);
  const [pages, setPages] = React.useState([]);

  const initialLoad = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/GetPages`)
    .then((response) => {
        if (!response.ok) {
          toast.error("Något gick fel när sidor skulle hämtas");
        }
        return response.json();
      }
    )
    .then((data) => setPages(data));
  }

  React.useEffect(() => {
    initialLoad();
  }, []);

  const handleChange = (event, newValue) => {
    setId(newValue)
    setContent(pages.find(p => p.title === newValue)?.content ?? '');
    setIdToUpdate(pages.find(p => p.title === newValue)?.id ?? null);
  };

  const deletePage = async (idToDelete) => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/api/DeletePage`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getTokenFromLocalStorage()}`
        },
        body: JSON.stringify(idToDelete),
      }).then((response) => {
        if (response.status === 401) {
          toast.error("Du har inte rättigheter att göra detta");
        }
        else if (!response.ok) {
          toast.error("Något gick fel när sidan skulle tas bort");
        }
        else {
          toast.success("Sida borttagen");
        }
      });
  
      initialLoad();
      props.reloadNavigation();
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const postResponse = async (data, title, idToUpdate) => {
    const fixedData = {
      title: title,
      content: data,
      id: idToUpdate,
    }
    if (title !== null && title !== '') {
      if (idToUpdate === null) {
        try {
          await fetch(`${process.env.REACT_APP_API_URL}/api/CreatePage`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getTokenFromLocalStorage()}`
            },
            body: JSON.stringify(fixedData),
          }).then((response) => {
            if (response.status === 401) {
              toast.error("Du har inte rättigheter att göra detta");
            }
            else if (!response.ok) {
              toast.error("Något gick fel när sidan skulle skapas");
            }
            else {
              toast.success("Sida skapad");
            }
          });
      
          initialLoad();
        } catch (error) {
          console.error("Error:", error);
        }
      }
      else {
        try {
          await fetch(`${process.env.REACT_APP_API_URL}/api/UpdatePage`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getTokenFromLocalStorage()}`
            },
            body: JSON.stringify(fixedData),
          }).then((response) => {
            if (response.status === 401) {
              toast.error("Du har inte rättigheter att göra detta");
            }
            else if (!response.ok) {
              toast.error("Något gick fel när sidan skulle uppdateras");
            }
            else {
              toast.success("Sida uppdaterad");
            }
          });
      
          initialLoad();
          props.reloadNavigation();
        } catch (error) {
          console.error("Error:", error);
        }
      }
    }
  }

  return (
    <div className='PageSettings'>
      <Tabs value={ id } onChange={ handleChange } >
      { pages.map((p, i) => (
        <Tab label={p.title} value={p.title} key={i} />
      )) }
      <Tab label='Ny sida' value='Ny sida' />
      </Tabs>
      { id &&
        <PageEditor pageId={ id } content={ content } postResponse={ postResponse } idToUpdate={ idToUpdate } deletePage={ deletePage } />
      }
    </div>
  );
}