import * as React from 'react';
import './index.css';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-hot-toast';

export default function Page(props) {
  const [content, setContent] = React.useState('');

  React.useEffect(() => {
    if (props.pageId !== "Anmälan" && props.pageId !== "Anmälda" && props.pageId !== "Admin" && props.pageId) {
      fetch(`${process.env.REACT_APP_API_URL}/api/GetPage?Id=${props.pageId}`)
      .then((response) => {
        if (!response.ok) {
          toast.error("Något gick fel när sidan skulle hämtas");
        }
        return response.json();
      })
      .then((data) => setContent(data.content));
    }
  }, [props.pageId]);

  if (content === null || content === undefined || content === '') {
    return (
      <CircularProgress />
    );
  }

  return (
    <div className='PageRoot' dangerouslySetInnerHTML={ { __html: `<div class="ck-content">${content}</div>` } }/>
  );
}