import * as React from 'react';
import { getTokenFromLocalStorage } from '../../../Common';
import { toast } from 'react-hot-toast';

const CreateUser = () => {
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');

  const signUp = async () => {
    const data = {
      username: username,
      password: password
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/CreateUser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getTokenFromLocalStorage()}`
        },
        body: JSON.stringify(data),
      }).then(response => response.json());
      if (response.status === 401) {
        toast.error("Du har inte rättigheter att göra detta");
      }
      else if (!response) {
        toast.error("Något gick fel när användare skulle skapas");
        console.log('Something went wrong when creating user: ', response);
        return;
      }
      else {
        toast.success("Användare skapad");
      }
    }
    catch (err) {
      console.log('Something went wrong when creating user: ', err);
    }
    finally {
    }
  };


  return (
    <div>
      <input
        type="text"
        placeholder="Användarnamn"
        value={username}
        onChange={(e) => { setUsername(e.target.value); }}
      />
      <input
        type="password"
        placeholder="*******" value={password}
        onChange={(e) => { setPassword(e.target.value); }}
      />
      <button onClick={signUp}>
        Skapa användare
      </button>
    </div>
  );
}

export default CreateUser;