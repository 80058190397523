import * as React from 'react';
import { useForm } from "react-hook-form";
import './index.css';
import { useContext } from 'react';
import { BalContext } from '../../BalContext';
import { toast } from 'react-hot-toast';

export default function Form() {
  const { register, handleSubmit, formState: { errors }, watch } = useForm();
  const [formSubmitted, setFormSubmitted] = React.useState(false);
  const [formResponse, setFormResponse] = React.useState();
  const [numberOfAttendees, setNumberOfAttendees] = React.useState(0);
  const [numberOfAttendeesAndReserve, setNumberOfAttendeesAndReserve] = React.useState(0);
  const [numberOfSexa, setNumberOfSexa] = React.useState(0);
  const balSettings = useContext(BalContext);
  const watchVisitCount = watch("previousVisits", 0);

  React.useEffect(() => {
    const getNumberOfAttendees = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/GetNumberOfAttendees`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          }
        }).then((response) => {
          if (!response.ok) {
            toast.error("Något gick fel när anmälda skulle hämtas");
          }
          return response.json();
        });
        setNumberOfAttendeesAndReserve(response.attendeesComingAndReserve)
        setNumberOfAttendees(response.attendeesComing)
        setNumberOfSexa(response.sexa)
      } catch (error) {
        console.error("Error:", error);
      }
    }

    getNumberOfAttendees();
  }, []);

  const onSubmit = (data) => {
    postResponse(data);
  }

  const postResponse = async (data) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/CreateFormResponse`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
  
      if (response.status === 409) {
        toast.error("Mailadressen är redan anmäld");
      }
      else if (!response.ok) {
        toast.error("Något gick fel med anmälan. Var god försök igen. Fungerar det ej, kontakta bal@blekingska.se");
      }
      const result = await response.json();

      setFormResponse(result);
      setFormSubmitted(true);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const getAlcoholText = (alcoholChoice) => {
    switch(alcoholChoice) {
      case 'alcohol':
        return 'Alkohol till maten';
      case 'alcoholFree':
        return `Alkoholfritt till maten: -${balSettings.alcoholFree} kr`;
      default:
        return '';
    }
  }

  const getTicketTypeText = (responseObject) => {
    switch(responseObject.responses.ticketType) {
      case 'student':
        return `Student á ${ balSettings.student } kr`;
      case 'nonStudent':
        return `Ickestudent á ${ balSettings.nonStudent } kr`;
      case 'active':
        return `Förtroendevald á ${ balSettings.active } kr`;
      case 'giver':
        return `Gynnare á ${ balSettings.giver } kr eller mer`;
      default:
        return '';
    }
  }

  const now = Date.now();
  let completelyFull = new Date(balSettings.hardEndDate) <= now || numberOfAttendeesAndReserve >= balSettings.maxAttendees;
  let reserveList = new Date(balSettings.reserveDate) <= now || numberOfAttendees >= balSettings.reserveAttendees;
  let sexaFull = numberOfSexa >= balSettings.maxSexaAttendees;
  if (new Date(balSettings.startDate) > now) {
    return (
      <p>Anmälan öppnar {balSettings.startDate}. Vi ses då!</p>
    );
  };

  if (completelyFull) {
    return (
      <p>Anmälan är tyvärr helt stängd!</p>
    );
  };

  if (formSubmitted) {
    if (reserveList) {
      return (
        <div>
          <h2>Du har blivit placerad på reservlistan för {balSettings.balName}!</h2>
          <p>
            <strong>Vi kommer att kontakta dig om en plats öppnar sig.</strong><br/>
            Kom ihåg att du fortfarande är hjärtligt välkommen på eftersläppet! <br/>
            Om du har några frågor, tveka inte att kontakta expen@blekingska.se
          </p>
          Du har angivit följande information:
          <p>
            Namn: { formResponse.responses.name }<br/>
            Personnummer: { formResponse.responses.idNumber }<br/>
            Email: { formResponse.responses.email }<br/>
            Telefonnummer: { formResponse.responses.phone }<br/>
            Adress: { formResponse.responses.address }<br/>
            Titel: { formResponse.responses.title }<br/>
            Följe: { formResponse.responses.company }<br/>
            {
              formResponse.responses.previousVisits && (<>Tidigare antal Höstblot: { formResponse.responses.previousVisits } <br/></>)
            }
          </p>
          <p>
            Beställning:<br/>
              { getTicketTypeText(formResponse) }<br/>
              { getAlcoholText(formResponse.responses.alcoholChoice) }<br/>
              {
                formResponse.responses.snaps && (<>Snabsbiljetter: { formResponse.responses.snaps } à { balSettings.snaps } kr<br/></>)
              }
              {
                formResponse.responses.medal && (<>{((formResponse.responses.previousVisits === 4 || formResponse.responses.previousVisits === 9) ? `Medalj inbyte: ${balSettings.medalTrade} kr` : `Medalj: ${balSettings.medal} kr`)}<br/></>)
              }
              {
                formResponse.responses.sexa && (<>Sexa: { balSettings.sexa } kr <br/></>)
              }
              {
                formResponse.responses.book && (<>Sångbok: { balSettings.book } kr <br/></>)
              }
              {
                formResponse.responses.lunch && (<>Ballunch: { balSettings.lunch } kr <br/></>)
              }
            Summa totalt{ formResponse.responses.ticketType === 'giver' && ' (eller mer)' }: { formResponse.totalPrice } kr 
          </p>
          <p>
            Du har angivit följande matpreferenser: <br/>
            Vegetarian: { formResponse.responses.vegetarian ? "Ja" : "Nej" }<br/>
            Vegan: { formResponse.responses.vegan ? "Ja" : "Nej" }<br/>
            Glutenfritt: { formResponse.responses.glutenFree ? "Ja" : "Nej" }<br/>
            Laktosfritt: { formResponse.responses.lactoseFree ? "Ja" : "Nej" }<br/>
            Övriga matpreferenser: { formResponse.responses.otherFoodPrefs ? (formResponse.responses.otherFoodPrefs) : "Inga"}<br/>
          </p>
          <p>
            Ett bekräftelsemail har skickats till { formResponse.responses.email }
          </p>
        </div>
      );
    }
    else {
      return (
        <div>
          <h2>Du är nu anmäld till {balSettings.balName}!</h2>
          <p>
            Betala in på följande bank-giro: <i>145-1939</i><br/>
            Ange följande referensnummer när du betalar in: <strong>{ formResponse.orderNumber }</strong><br/>
            <strong>Tänk på att din anmälan inte är fullbordad förrän du har betalat in!</strong>
          </p>
          Du har angivit följande information:
          <p>
            Namn: { formResponse.responses.name }<br/>
            Personnummer: { formResponse.responses.idNumber }<br/>
            Email: { formResponse.responses.email }<br/>
            Telefonnummer: { formResponse.responses.phone }<br/>
            Adress: { formResponse.responses.address }<br/>
            Titel: { formResponse.responses.title }<br/>
            Följe: { formResponse.responses.company }<br/>
            {
              formResponse.responses.previousVisits && (<>Tidigare antal Höstblot: { formResponse.responses.previousVisits } <br/></>)
            }
          </p>
          <p>
            Beställning:<br/>
              { getTicketTypeText(formResponse) }<br/>
              { getAlcoholText(formResponse.responses.alcoholChoice) }<br/>
              {
                formResponse.responses.snaps && (<>Snabsbiljetter: { formResponse.responses.snaps } à { balSettings.snaps } kr<br/></>)
              }
              {
                formResponse.responses.medal && (<>{((formResponse.responses.previousVisits === 4 || formResponse.responses.previousVisits === 9) ? `Medalj inbyte: ${balSettings.medalTrade} kr` : `Medalj: ${balSettings.medal} kr`)}<br/></>)
              }
              {
                formResponse.responses.sexa && (<>Sexa: { balSettings.sexa } kr <br/></>)
              }
              {
                formResponse.responses.book && (<>Sångbok: { balSettings.book } kr <br/></>)
              }
              {
                formResponse.responses.lunch && (<>Ballunch: { balSettings.lunch } kr <br/></>)
              }
            Summa totalt{ formResponse.responses.ticketType === 'giver' && ' (eller mer)' }: { formResponse.totalPrice } kr 
          </p>
          <p>
            Du har angivit följande matpreferenser: <br/>
            Vegetarian: { formResponse.responses.vegetarian ? "Ja" : "Nej" }<br/>
            Vegan: { formResponse.responses.vegan ? "Ja" : "Nej" }<br/>
            Glutenfritt: { formResponse.responses.glutenFree ? "Ja" : "Nej" }<br/>
            Laktosfritt: { formResponse.responses.lactoseFree ? "Ja" : "Nej" }<br/>
            Övriga matpreferenser: { formResponse.responses.otherFoodPrefs ? (formResponse.responses.otherFoodPrefs) : "Inga"}<br/>
          </p>
          <p>
            Ett bekräftelsemail har skickats till { formResponse.responses.email }
          </p>
        </div>
      );
    }
  }
  else {
    return (
      <>
        { reserveList && 
          <strong>Observera! Detta är endast en anmälan till reservlista, då balens deltagarlista är full. Vi kontaktar dig om en plats eventuellt öppnar sig.</strong>
        }
        <form onSubmit={ handleSubmit(onSubmit) }>
          {errors.ticketType &&  <p style={{ color: 'red' }}> {errors.ticketType && 'Var god välj biljettyp'} </p>}
          {errors.alcoholChoice &&  <p style={{ color: 'red' }}> {errors.alcoholChoice && 'Var god välj om du vill ha alkohol eller ej'} </p>}
          <div className='primary'>
            <label htmlFor='name'>Namn</label>
            <br/>
            <input
              id='name'
              { ...register('name', { required: true }) }
              type='text'
            />
            <br/>
            <label htmlFor='idNumber'>Personnummer</label>
            <br/>
            <input
              id='idNumber'
              { ...register('idNumber', { required: false }) }
              type='text'
            />
            <br/>
            <label htmlFor='address'>Adress</label>
            <br/>
            <textarea
              id='address'
              { ...register('address', { required: false }) }
              type='text'
            />
            <br/>
            <label htmlFor='email'>Email</label>
            <br/>
            <input
              id='email'
              { ...register('email', { required: true }) }
              type='email'
            />
            <br/>
            <label htmlFor='phone'>Telefonnummer</label>
            <br/>
            <input
              id='phone'
              { ...register('phone', { required: false }) }
              type='text'
            />
            <br/>
            <label htmlFor='title'>Titel</label>
            <br/>
            <input
              id='title'
              { ...register('title', { required: false }) }
              type='text'
            />
            <br/>
            <label htmlFor='company'>Följe <i className='smallItalic'>(Ange under följe om du vill sitta med någon särskild under balen)</i></label>
            <br/>
            <input
              id='company'
              { ...register('company', { required: false }) }
              type='text'
            />
            <p className="form-info">
              Glöm inte att ditt ev. följe även måste anmäla sig separat!
              Denna ruta har begränsat inflytande på bordsplaceringen.
              <br/>
              Blekingska nationen i Lund samlar in dessa personuppgifter i samband
              med köp av balbiljett för att planera { balSettings.balName } med exempelvis
              bordsplacering och för att kunna hålla kontakten med deltagarna före
              och efter baldagen. Mer information finns i nationens integritetspolicy
              som du kan hitta här: <a href="https://www.blekingska.se/policyer">blekingska.se/policyer</a>.
              Vid frågor eller för att begära att uppgifterna om dig raderas, kontakta
              <a href="mailto:q@blekingska.se">q@blekingska.se</a>.
            </p>
          </div>
          <div className='secondary'>
            <p>
              { balSettings?.student &&
                <>
                  <input
                    id='student'
                    { ...register('ticketType', { required: true }) }
                    type='radio'
                    name={ 'ticketType' }
                    value='student'
                  />
                  <label htmlFor='student'>Student: ({ balSettings?.student } kr)</label>
                  <br/>
                </>
              }
              { balSettings?.nonStudent &&
                <>
                  <input
                    id='nonStudent'
                    { ...register('ticketType', { required: true }) }
                    type='radio'
                    name={ 'ticketType' }
                    value='nonStudent'
                  />
                  <label htmlFor='nonStudent'>Ickestudent: ({ balSettings?.nonStudent } kr)</label>
                  <br/>
                </>
              }
              { balSettings?.active &&
                <>
                  <input
                    id='active'
                    { ...register('ticketType', { required: true }) }
                    type='radio'
                    name={ 'ticketType' }
                    value='active'
                  />
                  <label htmlFor='active'>Förtroendevald: ({ balSettings?.active } kr)</label>
                  <br/>
                </>
              }
              { balSettings?.giver &&
                <>
                  <input
                    id='giver'
                    { ...register('ticketType', { required: true }) }
                    type='radio'
                    name={ 'ticketType' }
                    value='giver'
                  />
                  <label htmlFor='giver'>Gynnare: ({ balSettings?.giver } kr)</label>
                  <br/>
                </>
              }
            </p>
            <p>
              <input
                id='alcohol'
                { ...register('alcoholChoice', { required: true }) }
                type='radio'
                name='alcoholChoice'
                value='alcohol'
              />
              <label htmlFor='alcohol'>Alkohol till maten: (0 kr)</label>
              <br/>
              <input
                id='alcoholFree'
                { ...register('alcoholChoice', { required: true }) }
                type='radio'
                name='alcoholChoice'
                value='alcoholFree'
              />
              <label htmlFor='alcoholFree'>Alkoholfritt till maten: ({ balSettings?.alcoholFree ? (`-${balSettings?.alcoholFree}`) : '0' } kr)</label>
            </p>
            <p>
              { balSettings?.book &&
                <>
                  <input
                    id='book'
                    { ...register('book', { required: false }) }
                    type='checkbox'
                  />
                  <label htmlFor='book'>Sångbok: ({ balSettings?.book } kr)</label>
                  <br/>
                </>
              }
              { (balSettings?.sexa && !sexaFull ) &&
                <>
                  <input
                    id='sexa'
                    { ...register('sexa', { required: false }) }
                    type='checkbox'
                  />
                  <label htmlFor='sexa'>Sexa: ({ balSettings?.sexa } kr)</label>
                  <br/>
                </>
              }
              { (balSettings?.sexa && sexaFull ) &&
                <>
                  <input
                    id='sexa'
                    { ...register('sexa', { required: false }) }
                    type='checkbox'
                    disabled={true}
                  />
                  <label htmlFor='sexa'><s>Sexa: ({ balSettings?.sexa } kr)</s> Sexan är tyvärr full</label>
                  <br/>
                </>
              }
              { balSettings?.medal &&
                <>
                  <input
                    id='medal'
                    { ...register('medal', { required: false }) }
                    type='checkbox'
                  />
                  <label htmlFor='medal'>{((watchVisitCount === "4" || watchVisitCount === "9" || watchVisitCount === 4 || watchVisitCount === 9) ? `Inbyte av medalj: (${balSettings.medalTrade} kr)` : `Medalj: (${balSettings.medal} kr)`)}</label>
                  <br/>
                </>
              }
              { balSettings?.lunch &&
                <>
                  <input
                    id='lunch'
                    { ...register('lunch', { required: false }) }
                    type='checkbox'
                  />
                  <label htmlFor='lunch'>Ballumch: ({ balSettings?.lunch } kr)</label>
                  <br/>
                </>
              }
            </p>
            { balSettings?.snaps &&
              <>
                <label htmlFor='snaps'>Snapsbiljetter: ({ balSettings?.snaps } kr)</label>
                <br/>
                <input
                  id='snaps'
                  { ...register('snaps', { required: false }) }
                  type='number'
                  min="0"
                  step="1"
                />
                <br/>
              </>
            }
            { balSettings?.medal &&
              <>
                <label htmlFor='previousVisits'>Tidigare antal Höstblot</label>
                <br/>
                <input
                  id='previousVisits'
                  { ...register('previousVisits', { required: false }) }
                  type='number'
                  min="0"
                  step="1"
                />
                <br/>
              </>
            }
            <p>
              <input
                id='vegetarian'
                { ...register('vegetarian', { required: false }) }
                type='checkbox'
              />
              <label htmlFor='vegetarian'>Vegetarian</label>
              <br/>
              <input
                id='vegan'
                { ...register('vegan', { required: false }) }
                type='checkbox'
              />
              <label htmlFor='vegan'>Vegan</label>
              <br/>
              <input
                id='lactoseFree'
                { ...register('lactoseFree', { required: false }) }
                type='checkbox'
              />
              <label htmlFor='lactoseFree'>Laktosfritt</label>
              <br/>
              <input
                id='glutenFree'
                { ...register('glutenFree', { required: false }) }
                type='checkbox'
              />
              <label htmlFor='glutenFree'>Glutenfritt</label>
              <br/>
              <br/>
              <label htmlFor='otherFoodPrefs'>Övriga matpreferenser</label>
              <br/>
              <textarea
                id='otherFoodPrefs'
                { ...register('otherFoodPrefs', { required: false }) }
                type='text'
              />
            </p>
            <br/>
            <input type='submit' value='Anmäl mig!' />
          </div>
        </form>
      </>
    );
  }
}