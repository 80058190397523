import * as React from 'react';
import './index.css';
import template from '../../../Content/ImportMall.xlsx'
import * as xlsx from 'xlsx';
import { getTokenFromLocalStorage } from '../../../Common';
import { CircularProgress } from '@mui/material';

export default function ImportAttendees() {

  const [parsedAttendees, setParsedAttendees] = React.useState([]);
  const [saved, setSaved] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);

  const resetAll = () => {
    setSaved(false);
    setParsedAttendees([]);
  }

  const importAttendees = async () => {
    setIsSaving(true);
    let resultAttendees = [];
    await Promise.all(parsedAttendees.map(async (attendee) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/CreateFormResponseFromImport`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getTokenFromLocalStorage()}`
          },
          body: JSON.stringify(attendee),
        });
  
        const result = await response.json();
  
        resultAttendees.push({
          name: result.responses.name,
          idNumber: result.responses.idNumber,
          address: result.responses.address,
          email: result.responses.email,
          phone: result.responses.phone,
          company: result.responses.company,
          title: result.responses.title,
          ticketType: result.responses.ticketType,
          book: result.responses.book,
          medal: result.responses.medal,
          sexa: result.responses.sexa,
          lunch: result.responses.lunch,
          snaps: result.responses.snaps,
          vegan: result.responses.vegan,
          vegetarian: result.responses.vegetarian,
          glutenFree: result.responses.glutenFree,
          lactoseFree: result.responses.lactoseFree,
          otherFoodPrefs: result.responses.otherFoodPrefs,
          alcoholChoice: result.responses.alcoholChoice,
          previousVisits: result.responses.previousVisits,
          importSuccess: true,
        });
      } catch (error) {
        console.error("Error:", error);
      }
    }));
    setParsedAttendees(resultAttendees);
    setSaved(true);
    setIsSaving(false);
  }

  const getTicketType = (ticketType) => {
    switch(ticketType) {
      case 'Student':
        return 'student';
      case 'Ickestudent':
        return 'nonStudent';
      case 'Gynnare':
        return 'giver';
      case 'Förtroendevald':
        return 'active';
      default:
        return 'student';
    }
  }

  const getAlcoholChoice = (alcoholChoice) => {
    switch(alcoholChoice) {
      case 'Alkohol':
        return 'alcohol';
      case 'Alkoholfritt':
        return 'alcoholFree';
      default:
        return 'alcohol';
    }
  }

  const getTicketTypePretty = (ticketType) => {
    switch(ticketType) {
      case 'student':
        return 'Student';
      case 'nonStudent':
        return 'Ickestudent';
      case 'giver':
        return 'Gynnare';
      case 'active':
        return 'Förtroendevald';
      default:
        return 'Student';
    }
  }

  const getAlcoholChoicePretty = (alcoholChoice) => {
    switch(alcoholChoice) {
      case 'alcohol':
        return 'Alkohol';
      case 'alcoholFree':
        return 'Alkoholfritt';
      default:
        return 'Alkohol';
    }
  }

  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = xlsx.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const json = xlsx.utils.sheet_to_json(worksheet);
            const parsedJson = json.map(x => ({
              name: x.Namn,
              idNumber: x.Personnummer,
              address: x.Adress,
              email: x.Email,
              phone: x.Telefonnummer,
              company: x.Följe,
              title: x.Titel,
              ticketType: getTicketType(x.Biljettyp),
              book: x.Sångbok === "Ja",
              medal: x.Medalj === "Ja",
              sexa: x.Sexa === "Ja",
              lunch: x.Ballunch === "Ja",
              snaps: isNaN(parseInt(x.Snapsbiljetter)) ? null : parseInt(x.Snapsbiljetter),
              vegan: x.Vegan === "Ja",
              vegetarian: x.Vegetarian === "Ja",
              glutenFree: x.Glutenfritt === "Ja",
              lactoseFree: x.Laktosfritt === "Ja",
              otherFoodPrefs: x['Övriga matpreferenser'],
              alcoholChoice: getAlcoholChoice(x['Alkohol eller alkoholfritt?']),
              previousVisits: isNaN(parseInt(x['Tidigare antal höstblot'])) ? null : parseInt(x['Tidigare antal höstblot']),
              importSuccess: false,
            }));
            setParsedAttendees(parsedJson);
        };
        reader.readAsArrayBuffer(e.target.files[0]);
    }
  }

  if (isSaving) {
    return (
      <CircularProgress />
    );
  }

  if (saved) {
    return (
      <div className='importParentContainer'>
        <button onClick={resetAll}>Importera flera</button>
        <div className='tableContainer'>
          <table>
            <thead>
              <tr>
                <th>Importresultat</th>
                <th>Namn</th>
                <th>Personnummer</th>
                <th>Adress</th>
                <th>Email</th>
                <th>Telefonnummer</th>
                <th>Följe</th>
                <th>Titel</th>
                <th>Biljettyp</th>
                <th>Sångbok</th>
                <th>Medalj</th>
                <th>Sexa</th>
                <th>Ballunch</th>
                <th>Antal snapsbiljetter</th>
                <th>Vegan</th>
                <th>Vegetarian</th>
                <th>Glutenfritt</th>
                <th>Laktosfritt</th>
                <th>Övriga matpreferenser</th>
                <th>Alkoholval</th>
                <th>Tidigare antal höstblot</th>
              </tr>
            </thead>
              <tbody>
              { parsedAttendees.map(x =>
                  (
                    <tr>
                      <td>{x.importSuccess ? "✅ Lyckades!" : "❌ Lyckades inte :("}</td>
                      <td>{x.name}</td>
                      <td>{x.idNumber}</td>
                      <td>{x.address}</td>
                      <td>{x.email}</td>
                      <td>{x.phone}</td>
                      <td>{x.company}</td>
                      <td>{x.title}</td>
                      <td>{getTicketTypePretty(x.ticketType)}</td>
                      <td>{x.book ? "Ja" : "Nej"}</td>
                      <td>{x.medal ? "Ja" : "Nej"}</td>
                      <td>{x.sexa ? "Ja" : "Nej"}</td>
                      <td>{x.lunch ? "Ja" : "Nej"}</td>
                      <td>{x.snaps}</td>
                      <td>{x.vegan ? "Ja" : "Nej"}</td>
                      <td>{x.vegetarian ? "Ja" : "Nej"}</td>
                      <td>{x.glutenFree ? "Ja" : "Nej"}</td>
                      <td>{x.lactoseFree ? "Ja" : "Nej"}</td>
                      <td>{x.otherFoodPrefs}</td>
                      <td>{getAlcoholChoicePretty(x.alcoholChoice)}</td>
                      <td>{x.previousVisits}</td>
                    </tr>
                  )
                )
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return (
    <div className='importParentContainer'>
      <a href={template} download><button>Ladda ner importmall</button></a>
      <p>Var god att ändra inte några av rubrikerna för kolumnerna i importmallen. Det är viktigt att de är som de är. Ändra inte heller flikarna Biljettyp, Alkoholval och JaEllerNej. Dessa är för datavalidering</p>
      <br/>
      <p>Lägg till rader i fliken anmälda och ladda upp filen med knappen nedanför. Den kommer visa en sammanställning. Du trycker sen på importera och ett resultat kommer visas. De anmälda får inte några mail vid anmälan och det normala kravet att en mailadress inte får användas två gånger finns inte för dessa anmälda. De behöver inte ha en mailadress alls egentligen. Denna import struntar i alla gränser som som reservlistor maxantal. Den anmäler ändå.</p>   
      <form>
          <input
              type="file"
              name="importAttendees"
              id="importAttendees"
              onChange={readUploadFile}
          />
      </form>
      { parsedAttendees.length > 0 && 
        (
          <>
            <div className='tableContainer'>
              <table>
                <thead>
                  <tr>
                    <th>Namn</th>
                    <th>Personnummer</th>
                    <th>Adress</th>
                    <th>Email</th>
                    <th>Telefonnummer</th>
                    <th>Följe</th>
                    <th>Titel</th>
                    <th>Biljettyp</th>
                    <th>Sångbok</th>
                    <th>Medalj</th>
                    <th>Sexa</th>
                    <th>Ballunch</th>
                    <th>Antal snapsbiljetter</th>
                    <th>Vegan</th>
                    <th>Vegetarian</th>
                    <th>Glutenfritt</th>
                    <th>Laktosfritt</th>
                    <th>Övriga matpreferenser</th>
                    <th>Alkoholval</th>
                    <th>Tidigare antal höstblot</th>
                  </tr>
                </thead>
                <tbody>
                  { parsedAttendees.map(x =>
                      (
                        <tr>
                          <td>{x.name}</td>
                          <td>{x.idNumber}</td>
                          <td>{x.address}</td>
                          <td>{x.email}</td>
                          <td>{x.phone}</td>
                          <td>{x.company}</td>
                          <td>{x.title}</td>
                          <td>{getTicketTypePretty(x.ticketType)}</td>
                          <td>{x.book ? "Ja" : "Nej"}</td>
                          <td>{x.medal ? "Ja" : "Nej"}</td>
                          <td>{x.sexa ? "Ja" : "Nej"}</td>
                          <td>{x.lunch ? "Ja" : "Nej"}</td>
                          <td>{x.snaps}</td>
                          <td>{x.vegan ? "Ja" : "Nej"}</td>
                          <td>{x.vegetarian ? "Ja" : "Nej"}</td>
                          <td>{x.glutenFree ? "Ja" : "Nej"}</td>
                          <td>{x.lactoseFree ? "Ja" : "Nej"}</td>
                          <td>{x.otherFoodPrefs}</td>
                          <td>{getAlcoholChoicePretty(x.alcoholChoice)}</td>
                          <td>{x.previousVisits}</td>
                        </tr>
                      )
                    )
                  }
                </tbody>
              </table>
            </div>
            <button onClick={importAttendees}>Importera anmälda</button>
          </>
        )
      }
    </div>
  );
};
