import * as React from 'react';
import './index.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

export default function PageEditor(props) {
  const [value, setValue] = React.useState('');
  const [pageTitle, setPageTitle] = React.useState('');

  React.useEffect(() => {
    if (props.pageId !== 'Ny sida') {
      setPageTitle(props.pageId);
    }
    else {
      setPageTitle('NY SIDA - UPPDATERA TITELN TACK');
    }
  }, [props.pageId]);


 React.useEffect(() => {
    setValue(props.content);
  }, [props.content]);

  const onSave = () => {
    props.postResponse(value, pageTitle, props.idToUpdate);
  }

  const onDelete = () => {
    props.deletePage(props.idToUpdate);
  }

  return (
    <div>
      <input type='text' value={pageTitle} onChange={(e) => {setPageTitle(e.target.value)}} />
      <CKEditor
        editor={Editor}
        data={value}
        onChange={(event, editor) => setValue(editor.getData())}
      />
      <button onClick={onSave}>Spara</button>
      <button onClick={onDelete}>Ta bort sida</button>
    </div>
  );
};
